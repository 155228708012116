<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label v-if="theme">{{ theme.name }}</ion-label>
				</ion-title>
				
				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content>

			<w-loadable :items="project" skelet="spinner" class="mt-3">
				<!-- <div class="navigation container d-flex ion-justify-content-end">
					<ion-icon color="primary" :icon="createOutline"></ion-icon>
				</div> -->
				<div class="main-content-container ion-justify-content-center">
					<div class="author">
						<img v-if="project.author.avatar" :src="project.author.avatar.path" alt="">
						<ion-icon :icon="personCircleOutline"></ion-icon>
						<h3 class="mt-2">{{ project.author.username }}</h3>
					</div>

					<div class="project">
						<img class="thumbnail mb-3" :src="project.image.path" alt="">

						<iframe class="mb-3" :src="project.iframe_url" frameborder="0"></iframe>

						<div class="project-name-wrapper d-flex ion-wrap mb-2">
							<h1>{{ project.name }}</h1>
							<div class="project-socials-buttons d-flex ion-align-items-center">
								<img class="facebook-icon" src="https://gamisfera.wezeo.dev/img/facebook-share.e689d410.png" alt="">
								<div class="like-button-wrapper d-flex ion-align-items-center ml-1">
									<!-- <div class="like-button d-flex ion-justify-content-center ion-align-items-center" :class="{'liked' : this.likedProject}" @click="projectToggleLike()"> -->
									<div class="like-button d-flex ion-justify-content-center ion-align-items-center" @click="projectToggleLike()">
										<!-- <img src="../_components/img/stiahnuť.png" alt=""> -->
										<ion-icon :icon="thumbsUpSharp"></ion-icon>
									</div>
									<p>{{ project.likes }}</p>
								</div>
							</div>
						</div>

						<ion-row class="project-options-wrapper mb-3">
							<ion-col v-if="project.download_url" size="12" size-md="6" size-lg="4">
								<ion-button>Sťiahnúť projekt</ion-button>
							</ion-col>
							<ion-col v-if="project.external_url" size="12" size-md="6" size-lg="4">
								<ion-button>Stránka projektu</ion-button>
							</ion-col>
							<ion-col v-if="project.fb_url" size="12" size-md="6" size-lg="4">
								<ion-button>Facebook stránka</ion-button>
							</ion-col>
						</ion-row>

						<p class="description mb-0" v-html="project.description" />

						<h2 class="mb-2">Komentáre</h2>

						<p v-if="comments.length == 0" class="mb-3">Tento projekt zaťiaľ nikto nekomentoval</p>

						<div v-for="comment in comments" :key="comment.id" class="comment-wrapper width-100 mb-2 pl-1">
							<div class="d-flex">
								<img :src="comment.author.avatar.path" alt="">
								<div>
									<p class="username">{{ comment.author.username }}</p>
									<p class="date">{{ comment.updated_at }}</p>
								</div>
							</div>
							<p class="mb-2 pl-1 pr-2">{{ comment.content }}</p>
						</div>

						<h4>Pridať komentár</h4>
						<ion-textarea class="widht-100" placeholder="Váš komentár..."></ion-textarea>
						<ion-button class="mb-3">Pridať</ion-button>
					</div>
				</div>
			</w-loadable>

		</ion-content>
	</ion-page>
</template>

<script>
import { arrowBackOutline, createOutline, personCircleOutline, logInOutline, thumbsUpSharp } from 'ionicons/icons'
import wAxios from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'

const api = {
	getProject: (id) => wAxios.get_data(`https://gamisfera.hemisfera.sk/cms/api/projects/${id}`),
	getComments: (id) => wAxios.get_data(`https://gamisfera.hemisfera.sk/cms/api/comments/project/${id}`),
}

export default {
	data() {
		return {
			arrowBackOutline,
			createOutline,
			personCircleOutline,
			logInOutline, thumbsUpSharp,
			likedProject: false,
			project: null,
			comments: null
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},

	created() {
		this._loadProject()
	},

	methods: {
		async _loadProject() {
			try {
				const project = await api.getProject(this.$route.params.id)
				this.project = project
				const comments = await api.getComments(this.$route.params.id)
				this.comments = comments
			}
			catch(error) {
				console.error(error)
			}

			console.log(this.project)
		},

		projectToggleLike() {
			if (!this.likedProject) {
				this.likedProject = true
				this.project.likes += 1
			} else {
				this.likedProject = false
				this.project.likes -= 1
			}
		},
	}
}
</script>

<style lang="sass" scoped>

.main-content-container
	display: flex
	max-width: 1180px
	padding: 0 25px
	margin: auto

.navigation
	padding: 0 40px 10px
	border-bottom: 1px solid rgba(0,0,0,.1)
	margin: 0 auto
	width: 95%

	ion-icon
		width: 30px
		height: 30px

.author
	width: 250px
	padding-top: 30px
	border-right: 1px solid rgba(0,0,0,.1)
	text-align: center

	img
		width: 120px
		height: 120px
		border-radius: 50%

	ion-icon
		width: 120px
		height: 120px

	h3
		font-size: 17px
		color: #2b3d55
		margin: 0 15px 15px 

ion-button
	max-width: 100%
	--border-radius: 50px

.project
	width: calc( 100% - 250px )
	padding: 30px

	.thumbnail
		width: 100%
		max-height: 450px
		object-fit: cover

	iframe
		border: 1px solid lightgray
		border-radius: 8px
		width: 100%
		height: 450px

	h1, h2
		font-size: 30px
		color: #2b3d55
		margin: 0

	.project-name-wrapper
		justify-content: space-between

	.project-socials-buttons
		.facebook-icon
			height: 25px

		.like-button-wrapper
			background-color: white
			height: fit-content
			padding: 3px
			border-radius: 50px

			.like-button
				height: 40px
				width: 40px
				padding: 0
				background: #e6e7ff
				transition: .5s
				border-radius: 50%

				&:hover, &:focus
					background: var(--ion-color-primary)

					ion-icon
						color: white

				&.liked
					background: var(--ion-color-primary)

					ion-icon
						color: white

				img
					height: 20px
					width: 20px

			p
				margin: 0 12px 0 10px
				font-size: 14px
				
	p
		font-size: 16px
		
	h4
		font-size: 20px

	ion-textarea
		border: 1px solid gray
		padding: 0 10px 
		border-radius: 10px
		margin: 30px 0 10px

.comment-wrapper
	border-bottom: 1px solid rgba(0, 0, 0, 0.2)

	img
		width: 50px
		height: 50px
		margin: 0 20px 20px 0

	.username
		font-size: 20px
		font-weight: 400
		margin-bottom: 5px

	.date
		font-size: 13px

@media only screen and (max-width: 850px)
	.author
		width: 160px
		img
			width: 100px
			height: 100px

	.project
		width: calc( 100% - 160px )

@media only screen and (max-width: 675px)
	.navigation
		padding: 0 15px 10px

	.main-content-container
		display: block
		padding: 0 15px

	.author
		width: 100%
		border-right: none

	.project
		padding: 0
		width: 100%
		border-top: none

@media only screen and (max-width: 423px)
	.project-name-wrapper
		justify-content: center !important


</style>